import $ from "jquery";

var tarifapi = {
    mstripe : {
        registerElements : function (elements, exampleName, subscriptionId, clientSecret) {
            var formClass = '.' + exampleName;
            var example = document.querySelector(formClass);

            var form = example.querySelector('form');
            var resetButton = example.querySelector('a.reset');
            var error = form.querySelector('.error');
            var errorMessage = error.querySelector('.message');

            function enableInputs() {
                Array.prototype.forEach.call(
                form.querySelectorAll(
                    "input[type='text'], input[type='email'], input[type='tel']"
                ),
                function(input) {
                    input.removeAttribute('disabled');
                }
                );
            }

            function disableInputs() {
                Array.prototype.forEach.call(
                form.querySelectorAll(
                    "input[type='text'], input[type='email'], input[type='tel']"
                ),
                function(input) {
                    input.setAttribute('disabled', 'true');
                }
                );
            }

            function triggerBrowserValidation() {
                // The only way to trigger HTML5 form validation UI is to fake a user submit
                // event.
                var submit = document.createElement('input');
                submit.type = 'submit';
                submit.style.display = 'none';
                form.appendChild(submit);
                submit.click();
                submit.remove();
            }

            // Listen for errors from each Element, and show error messages in the UI.
            var savedErrors = {};
            elements.forEach(function(element, idx) {
                element.on('change', function(event) {
                if (event.error) {
                    error.classList.add('visible');
                    savedErrors[idx] = event.error.message;
                    errorMessage.innerText = event.error.message;
                } else {
                    savedErrors[idx] = null;

                    // Loop over the saved errors and find the first one, if any.
                    var nextError = Object.keys(savedErrors)
                    .sort()
                    .reduce(function(maybeFoundError, key) {
                        return maybeFoundError || savedErrors[key];
                    }, null);

                    if (nextError) {
                    // Now that they've fixed the current error, show another one.
                    errorMessage.innerText = nextError;
                    } else {
                    // The user fixed the last error; no more errors.
                    error.classList.remove('visible');
                    }
                }
                });
            });

            // Listen on the form's 'submit' handler...
            form.addEventListener('submit', function(e) {

                e.preventDefault();

                // Trigger HTML5 validation UI on the form if any of the inputs fail
                // validation.
                var plainInputsValid = true;
                Array.prototype.forEach.call(form.querySelectorAll('input'), function(
                input
                ) {
                if (input.checkValidity && !input.checkValidity()) {
                    plainInputsValid = false;
                    return;
                }
                });
                if (!plainInputsValid) {
                triggerBrowserValidation();
                return;
                }

                // Show a loading screen...
                example.classList.add('submitting');

                // Disable all inputs.
                disableInputs();

                // Gather additional customer data we may have collected in our form.
                var name = form.querySelector('#' + exampleName + '-name');
                var zip = form.querySelector('#' + exampleName + '-zip');
                var phone = form.querySelector('#' + exampleName + '-phone');
                var phone = form.querySelector('#' + exampleName + '-phone');
                var additionalData = {
                    address : {
                        postal_code: zip ? zip.value : undefined
                    },
                    name: name ? name.value : undefined,
                    phone : phone ? phone.value : undefined
                };


                 // Create payment method and confirm payment intent.
                    tarifapi.mstripe.get().confirmCardPayment(clientSecret, {
                        payment_method: {
                        card: elements[0],
                        billing_details: additionalData,
                        },
                        setup_future_usage : 'off_session'
                    }).then((result) => {
                        if(result.error) {
                            console.log(result);
                        } else {
                            tarifapi.save_default_pm(result.paymentIntent)
                        }
                    });

/*

                tarifapi.mstripe.get().confirmCardSetup(
                    clientSecret, {
                        payment_method: {
                            card: elements[0],
                            billing_details: additionalData
                        }
                    }
                ).then(function(result){
                    example.classList.remove('submitting');
                    if (result.error) {
                        // Display "error.message" to the user...
                        enableInputs();
                    } else {
                        // The card has been verified successfully...
                        tarifapi.savePayement_method_and_pay(result.setupIntent, id_tarif);
                        example.classList.add('submitted');
                    }
                });*/
            });

            resetButton.addEventListener('click', function(e) {
                e.preventDefault();
                // Resetting the form (instead of setting the value to `''` for each input)
                // helps us clear webkit autofill styles.
                form.reset();

                // Clear each Element.
                elements.forEach(function(element) {
                element.clear();
                });

                // Reset error state as well.
                error.classList.remove('visible');

                // Resetting the form does not un-disable inputs, so we need to do it separately:
                enableInputs();
                example.classList.remove('submitted');
            });
        },
        stripe : null,
        get : function(){
            if(tarifapi.stripe == null){
                tarifapi.stripe = Stripe(_tarifapi.STRIPE_KEY);
            }
            return tarifapi.stripe;
        },
        styles : {
            elementStyles : {
                base: {
                color: '#e6e9ed',
                // fontWeight: 600,
                // fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
                // fontSize: '13px',
                // fontSmoothing: 'antialiased',

                ':focus': {
                    color: '#e6e9ed',
                },

                '::placeholder': {
                    color: '#9BACC8',
                },

                ':focus::placeholder': {
                    color: '#CFD7DF',
                },
                },
                invalid: {
                color: '#eb1c26',
                ':focus': {
                    color: '#FA755A',
                },
                '::placeholder': {
                    color: '#FFCCA5',
                },
                },
            },
            elementClasses : {
                focus: 'focus',
                empty: 'empty',
                invalid: 'invalid',
            }
        },
        mountCard : function(subscriptionId, clientSecret){
            $('#fdlt_pay').show();
            var elements = tarifapi.mstripe.get().elements();

            var cardNumber = elements.create('cardNumber', {
                style: tarifapi.mstripe.styles.elementStyles,
                classes: tarifapi.mstripe.styles.elementClasses,
            });
            cardNumber.mount('#fdlt_pay-card-number');

            var cardExpiry = elements.create('cardExpiry', {
                style: tarifapi.mstripe.styles.elementStyles,
                classes: tarifapi.mstripe.styles.elementClasses,
            });
            cardExpiry.mount('#fdlt_pay-card-expiry');

            var cardCvc = elements.create('cardCvc', {
                style: tarifapi.mstripe.styles.elementStyles,
                classes: tarifapi.mstripe.styles.elementClasses,
            });
            cardCvc.mount('#fdlt_pay-card-cvc');

            tarifapi.mstripe.registerElements([cardNumber, cardExpiry, cardCvc], 'fdlt_pay', subscriptionId, clientSecret);
        }

    },

    save_default_pm : function(paymentIntent){
        $.post(_tarifapi.routes.save_default_pm, {
            _token : _tarifapi._token,
            payment_method : paymentIntent.payment_method
        }, function(data) {
            console.log(data);
            alert('Paiement réussi, et carte enregistrée pour les prochains prelevements');
        }).fail(function(response) {
            console.log(response);
        });
    },
    savePayement_method : function(setupIntent){

        $.post(_tarifapi.routes.setup_intent, {
            _token : _tarifapi._token,
            setupIntent : setupIntent
        }, function(data) {
            console.log(data);
            $('#pay_tarif').prop( "disabled", false );
        }).fail(function(response) {
            console.log(response);
        });
        console.log("savePayement_method");
    },
    pay : function(pm, id_tarif){
        console.log("payement starting");
        $.post(_tarifapi.routes.pay, {
            '_token' : _tarifapi._token,
            'payment_method' : pm,
            'id_tarif' : id_tarif
        }, function(data) {
            console.log(data);
            if(typeof data.payment_intent != "undefined"){
                tarifapi.mstripe.get().confirmCardPayment (data.payment_intent.client_secret).then(function(result){
                    alert(result.paymentIntent.status);
                })
            }
        }).fail(function(response) {
            console.log(response);
        });
        console.log("savePayement_method");
    },

    show_payment_intent : function(id_tarif){
        $.get(_tarifapi.routes.show_payment_intent, {
            _token : _tarifapi._token,
            id_tarif : id_tarif
        }, function(data) {
            $('#zone_card').html(data);
            tarifapi.mstripe.mountCard();
        }).fail(function(response) {
            console.log(response);
        });
    },
    createSubscriptionIncomplete : function(id_tarif){
        $.get(_tarifapi.routes.createSubscriptionIncomplete, {
            _token : _tarifapi._token,
            id_tarif : id_tarif
        }, function(data) {
            window.sessionStorage.setItem('subscriptionId', data.subscriptionId);
            window.sessionStorage.setItem('clientSecret', data.clientSecret);
            tarifapi.mstripe.mountCard(data.subscriptionId, data.clientSecret);
        }).fail(function(response) {
            console.log(response);
        });
    },
    clear_recap : function(){
        $('#nom_tarif').text("");
        $('#prix_tarif').text("");
        $('#prix_button_tarif').text("");
        $('#Interval_tarif').text("");
        $('#default_pm_tarif').text("");
        $('#pay_tarif span.loadingButtonInitial').removeClass('hidden');
        $('#pay_tarif svg.animate-spin').removeClass('hidden');
        $('#pay_tarif span.loadingButtonText').addClass('hidden');
    },
    xhr_recap : null,
    show_recap : function(id_tarif){

        if(tarifapi.xhr_recap != null){
            tarifapi.xhr_recap.abort();
            tarifapi.xhr_recap = null;
        }

        var url = _tarifapi.routes.recap_before_pay.replace('param_id_tarif', id_tarif);



        tarifapi.xhr_recap = $.get(url, {
            _token : _tarifapi._token,
            id_tarif : id_tarif
        }, function(data) {
            if(data.success){
                console.log(data);
                $('#nom_tarif').text(data.tarif.nom);
                $('#prix_tarif').text(""+(data.tarif.unit_amount/100) +" "+ data.tarif.currency);
                $('#prix_button_tarif').text(""+(data.tarif.unit_amount/100) +" "+ data.tarif.currency);
                $('#Interval_tarif').text(" / "+data.tarif.interval);
                $('#description_tarif').text(data.tarif.description);
                if(data.tarif.images.length > 0){
                    $('#image_tarif').attr('src', data.tarif.images[0].uri);
                }

                if(data.default_pm != null){
                    $('#zone_pm_connu').show();
                    $('#default_pm_tarif').html("**** **** **** "+data.default_pm.card.last4+" &nbsp; "+data.default_pm.card.exp_month+"/"+data.default_pm.card.exp_year);
                    $('#pay_tarif').data('pm', data.default_pm.id);
                    $('#pay_tarif').prop( "disabled", false );
                }else{
                    tarifapi.createSubscriptionIncomplete(id_tarif);
                }

                $('#pay_tarif span.loadingButtonInitial').addClass('hidden');
                $('#pay_tarif svg.animate-spin').addClass('hidden');
                $('#pay_tarif span.loadingButtonText').removeClass('hidden');

                $('#pay_tarif').data('id_tarif', id_tarif);
            }
        }).fail(function(response) {
            console.log(response);
        });
    }
}

$(function() {



    $(document).on('click', '.fdlt_tarif_jemabonne', function(){
        tarifapi.clear_recap();
        toggleModal('modal_tarif');
        tarifapi.show_recap($(this).data('id_tarif'))
    });

    $(document).on('click', '#pay_tarif', function(){
        var pm = $(this).data('pm');
        var id = $(this).data('id_tarif');
        tarifapi.pay(pm, id);
    });
})


export {tarifapi};


/*


        */
