import $ from "jquery";
$("#nouveautes").click(function(){
        $(this).removeClass('fdlt-barnav-inactive');
        $(this).addClass('fdlt-barnav-active');
        $('#abonnements').removeClass('fdlt-barnav-active');
        $('#abonnements').addClass('fdlt-barnav-inactive');   
        $('#blocabo').addClass('hidden');
        $('#blocnews').removeClass('hidden');      
    
});
$("#abonnements").click(function(){
        $(this).removeClass('fdlt-barnav-inactive');
        $(this).addClass('fdlt-barnav-active');
        $('#nouveautes').removeClass('fdlt-barnav-active');
        $('#nouveautes').addClass('fdlt-barnav-inactive');
        $('#blocnews').addClass('hidden');
        $('#blocabo').removeClass('hidden');

});