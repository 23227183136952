if(document.getElementById('app_profile_edit') != null){

    document.addEventListener('alpine:init', () => {

        Alpine.data('app_profile_edit', (routeLoad, routeUpdate) =>({
            profile : {},
            init(){
              this.loadprofile();
            },
            loadprofile() {
                axios({
                    method: 'get',
                    url: routeLoad,
                }).then(response =>{
                    this.profile = response.data;
                });
            },
            updateprofile(){
                console.log(this.profile);


                axios.put(routeUpdate, {
                    'name' : this.profile.name,
                    'lastname' : this.profile.lastname
                },{}).then(response =>{
                    window.location.reload();
                });
            },
        }))
    })

}
