import './bootstrap';
import $ from "jquery";
import Alpine from 'alpinejs';
import dayjs from 'dayjs';
require('dayjs/locale/fr')
dayjs.locale('fr')

window.currency_symbols = {
    'USD': '$', // US Dollar
    'EUR': '€', // Euro
    'CRC': '₡', // Costa Rican Colón
    'GBP': '£', // British Pound Sterling
    'ILS': '₪', // Israeli New Sheqel
    'INR': '₹', // Indian Rupee
    'JPY': '¥', // Japanese Yen
    'KRW': '₩', // South Korean Won
    'NGN': '₦', // Nigerian Naira
    'PHP': '₱', // Philippine Peso
    'PLN': 'zł', // Polish Zloty
    'PYG': '₲', // Paraguayan Guarani
    'THB': '฿', // Thai Baht
    'UAH': '₴', // Ukrainian Hryvnia
    'VND': '₫', // Vietnamese Dong
};


window.Alpine = Alpine;
window.$ = $;
Alpine.start();

import { toggleModal } from './layout/modal';
window.toggleModal = toggleModal;

import './layout/auth/quickcreation_pro';

import './layout/auth/product';

import { tarifapi }  from './layout/auth/tarif';
window.tarifapi = tarifapi;


import './layout/barnav';
import './layout/pro_create';

import './layout/auth/v_listMySub';
import './layout/auth/profile_listMySub';
import './layout/auth/profile_listMesFactures';

import './layout/auth/feed_editor';
import './layout/auth/feed_list';

import './layout/auth/follower';
import './layout/share';

import './layout/auth/profile';
