if(document.getElementById('app_follow_zone') != null){

    document.addEventListener('alpine:init', () => {

        Alpine.data('app_follow_zone', (route, follower) =>({
            isFollower : false,
            toggling : false,
            init(){
                this.isFollower = follower;
            },
            toggle(){
                if(this.toggling){
                    return;
                }
                this.toggling = true;

                axios.post(route, {'newStatut' : !this.isFollower},{
                }).then(response =>{
                    this.isFollower = response.data.isFollower;
                    this.toggling = false;
                }).catch(thrown =>{
                    this.toggling = false;
                  });
            }
        }))
    })

}
