//https://github.com/RWDevelopment/alpine_js_slider
if(document.getElementById('app_sliderForm') != null){
    document.addEventListener('alpine:init', () => {
        Alpine.data('app_sliderForm', (route, formData) => ({

            // set initial tab
            tab: -1,

            progress : 0,

            // slider tabs
            tabs: [...document.querySelectorAll('div#tabs_form[role=tablist] a[role=tab]')],

            currentInput : null,
            showButtonNext : false,

            init() {
                // initialize main function
                this.route = route;
                this.formData = formData;
                this.$nextTick(() => {
                    this.changeSlide()
                })
            },

            // main function
            changeSlide() {
                if(this.tab == -1){
                    this.tab = 0;
                }
                this.tabs[this.tab].classList.add('class', 'tab-active')
                this._updateProgressBarWithTabPosition();

                // slider tabs click event
                this.tabs.forEach( (tab)=> {
                    tab.addEventListener('click', (e)=> {
                        e.preventDefault()
                        this.tab = this.tabs.indexOf(e.target)
                        this.tabs.forEach( (tab)=> {
                            (this.tab == this.tabs.indexOf(tab)) ?  tab.classList.add('tab-active') : tab.classList.remove('tab-active');
                            this._updateProgressBarWithTabPosition();
                        })
                    })
                })
            },
            _updateProgressBarWithTabPosition(){
                this.progress = (100 / this.tabs.length) * (this.tab + 1);

                this.changed()
            },
            changed(e){
                if(this.tab == -1){return;}
                var show = true;
                if(this.tabs[this.tab].getAttribute('inputkey')){
                    var listinputKeyRequired = this.tabs[this.tab].getAttribute('inputkey').split(',');
                    for (let i = 0; i < listinputKeyRequired.length; i++) {
                        if(typeof this.formData[listinputKeyRequired[i]] == "undefined"){
                            show = false;
                            break;
                        }
                        let v = this.formData[listinputKeyRequired[i]].trim();
                        if(v == ""){
                            show = false;
                            break;
                        }
                    }
                }

                this.showButtonNext = show;
            },
            next(){
                if(this.tab == this.tabs.length - 1){
                    //console.log('derniere tab');
                    return;
                }
                this.tab = this.tab + 1;
                this.tabs.forEach( (tab)=> {
                    (this.tab == this.tabs.indexOf(tab)) ?   tab.classList.add('tab-active') : tab.classList.remove('tab-active');
                    this._updateProgressBarWithTabPosition();
                })

            },
            previous(){
                if(this.tab == 0){
                    //console.log('premiere tab');
                    return;
                }
                this.tab = this.tab - 1 ;
                this.tabs.forEach( (tab)=> {
                    (this.tab == this.tabs.indexOf(tab)) ?   tab.classList.add('tab-active') : tab.classList.remove('tab-active');
                    this._updateProgressBarWithTabPosition();
                })
                console.log(this.tab);
            },
            poster(){
                var myForm = document.getElementById('app_quickcreation_pro_form');
                let fd = new FormData(myForm);

                if(fd.get('description') == '' && typeof fd.get('app_feededitor_file[]') == 'object'){
                    return;
                }

                fd.append("csrf-token", document.head.querySelector('meta[name="csrf-token"]').content);

                axios.post(this.route, fd,{
                }).then(response =>{
                    if(response.data.redirect){
                        window.location.replace(response.data.redirect);
                    }
                });
            }
        }))
    })
}
