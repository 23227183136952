
import * as FilePond from 'filepond';
import pt_fr from 'filepond/locale/fr-fr';
FilePond.setOptions(pt_fr);
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

FilePond.registerPlugin(
    FilePondPluginImagePreview,
    FilePondPluginImageExifOrientation,
    FilePondPluginFileValidateSize,
    FilePondPluginImageEdit,
    FilePondPluginImageResize,
    FilePondPluginImageTransform,
    FilePondPluginFileValidateType
);

FilePond.setOptions({
    server: {
      url: '/filepond/api',
      process: '/process',
      revert: '/process',
      patch: "?patch=",
      headers: {
        'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
      }
    }
  });



if(document.getElementById('app_feededitor') != null){

    document.addEventListener('alpine:init', () => {

        Alpine.data('app_feededitor', (route) =>({
            listsub : [],
            zoneUpload : false,
            lefilepond : null,
            formData : {
                description : '',
                etat : '',
                forsub : '',
                app_feededitor_file : '',
            },
            init(){
                this.formData.etat = document.querySelector("#app_feededitor form input[name='etat']").value
                this.formData.forsub = document.querySelector("#app_feededitor form input[name='forsub']").value


                this.lefilepond = FilePond.create(
                    document.querySelector('#app_feededitor_file'),{
                        imageResizeTargetWidth: 1280,
                        acceptedFileTypes: ['image/*'],
                });
            },
            poster(){
                if(this.lefilepond.status == 2 || this.lefilepond.status == 3){
                    return;
                }

                var myForm = document.getElementById('app_feededitor_form');
                let fd = new FormData(myForm);

                if(fd.get('description') == '' && typeof fd.get('app_feededitor_file[]') == 'object'){
                    return;
                }

                fd.append("csrf-token", document.head.querySelector('meta[name="csrf-token"]').content);

                axios.post(route, fd,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response =>{
                    this.resetForm();
                    document.getElementById('app_feed').dispatchEvent(new CustomEvent('refreshfeed', { detail: {}}))
                });
            },
            resetForm(){
                this.lefilepond.removeFiles({ revert: true });
                this.zoneUpload = false;
                this.formData.description = "";
            }
        }))
    })

}
