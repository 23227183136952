if(document.getElementById('app_share_zone') != null){

    document.addEventListener('alpine:init', () => {

        Alpine.data('app_share_zone', (route) =>({
            libQR : false,
            txtCopyOrigin : '✂️ Copier le lien',
            txtCopy : '✂️ Copier le lien',
            pasdeQR : false,
            init(){
                this.route = route;
                if(typeof QRCode == "undefined"){
                    this.pasdeQR = true;
                }
                //document.getElementById('app_share_zone').dispatchEvent(new CustomEvent('refreshsharezone', { detail: {route : '{{route('fiche_pro',['nom_uri' => $pro->nom_uri])}}'}}))
                //document.getElementById('app_share_zone').dispatchEvent(new CustomEvent('refreshsharezone', { detail: {route : "machin"}}))
            },
            loadsharezone(d){
                console.log(d);
                this.route = d.route;
                document.getElementById('my-modal-sharzone').checked = true;
                if(typeof QRCode == "undefined"){
                    this.pasdeQR = true;
                }else{
                    this.QrCoder()
                }

            },
            CopierLien(){
                navigator.clipboard.writeText(this.route);
                console.log(this.route);
                this.txtCopy = "Lien copié ! 😊";
                setTimeout(() => this.txtCopy = this.txtCopyOrigin, 1000)
            },
            QrCoder(){
                // Options
                var options = {
                    text: this.route,
                    backgroundImage : '/images/logo2.png',
                    backgroundImageAlpha : 0.5,
                    logo : '/images/logo2_modicon.png',
                    logoBackgroundTransparent : true,
                };
                if(this.libQR == false){
                    // Create QRCode Object
                    this.libQR = new QRCode(document.getElementById("qrcode"), options);
                }else{
                    this.libQR.makeCode(this.route);
                }

            }
            })
    )
    })

}
