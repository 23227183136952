import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
if(document.getElementById('app_listMySubGerer') != null){

    document.addEventListener('alpine:init', () => {

        Alpine.data('app_listMySubGerer', (route) =>({
            listsub : [],
            init(){
                this.refresh()
            },
            refresh(){
                axios({
                    method: 'get',
                    url: route,
                    data: {
                        filterStatus: 'active',
                      }
                }).then(response =>{
                    this.listsub = JSON.parse(JSON.stringify(response.data));

                });
            }
        }))
    })

}
