

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
if(document.getElementById('app_listMesFactures') != null){

    document.addEventListener('alpine:init', () => {

        Alpine.data('app_listMesFactures', (route, limit) =>({
            invoices : [],
            upcomingInvoice : null,
            displayLinkMoreInvoice : false,
            displayAucuneFacture : false,
            init(){
                this.refresh()
                this.limit = limit;
            },
            refresh(){
                axios({
                    method: 'get',
                    url: route,
                }).then(response =>{
                    // à venir

                    let _upcomingInvoice = response.data.upcomingInvoice;
                    if(_upcomingInvoice){
                        this.upcomingInvoice = _upcomingInvoice;
                        this.upcomingInvoice.next_payment_attempt_readable = dayjs(this.upcomingInvoice.next_payment_attempt * 1000).format('DD MMM YYYY')
                    }

                    if(this.limit && this.limit != -1){
                        if(response.data.invoices.length > this.limit){
                            response.data.invoices.pop();
                            this.displayLinkMoreInvoice = true;
                        }
                    }

                    for (let i = 0; i <  response.data.invoices.length; i++) {
                        response.data.invoices[i]['period_start_readable']= dayjs(response.data.invoices[i]['period_start']).format('DD MMM YYYY');

                    }
                    this.invoices =  response.data.invoices;

                    if(this.invoices.length == 0){
                        this.displayAucuneFacture = true;
                    }
                });
            }
        }))
    })

}
