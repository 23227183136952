import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
if(document.getElementById('app_listMySub') != null){

    document.addEventListener('alpine:init', () => {

        Alpine.data('app_listMySub', (route, route_utal, route_invoices) =>({
            listsub : [],
            invoices : [],
            displayAucuneFacture : false,
            init(){
                this.route_utal = route_utal;
                this.route_invoices = route_invoices ?? null;
                this.refresh();

                if(this.route_invoices != null){
                    this.listInvoiceload();
                }
            },

            refresh(){
                this.listsub = [];
                axios({
                    method: 'get',
                    url: route,
                    data: {
                        filterStatus: 'active',
                      }
                }).then(response =>{
                    for (let i = 0; i < response.data.length; i++) {
                        for (let j = 0; j < response.data[i].list_uta_ta.length; j++) {
                            response.data[i].list_uta_ta[j]["uta_date_end_action_beautiful"] = dayjs(response.data[i].list_uta_ta[j]?.uta_date_end_action).fromNow(true);
                            response.data[i].list_uta_ta[j]["uta_date_end_action_beautiful_is_before"] = dayjs(response.data[i].list_uta_ta[j]?.uta_date_end_action).isBefore(dayjs());
                            for(let k=0; k < response.data[i].list_uta_ta[j].utals.length; k++){
                                response.data[i].list_uta_ta[j].utals[k].created_beautiful = dayjs(response.data[i].list_uta_ta[j].utals[k].created).format('DD MMM YYYY HH:mm')
                            }
                        }

                        response.data[i].s_created_at_beautiful = dayjs(response.data[i].s_created_at).format('DD MMM YYYY');
                        // On est sur une page unique
                        if(response.data[i].isUniquePageSub){
                            let sub_stripe = response.data[i].stripe;

                            sub_stripe.current_period_start_beautiful = dayjs(sub_stripe.current_period_start * 1000).format('DD MMM YYYY');
                            sub_stripe.current_period_end_beautiful = dayjs(sub_stripe.current_period_end * 1000).format('DD MMM YYYY');
                            if(sub_stripe.cancel_at_period_end){
                                sub_stripe.cancel_at_beautiful = dayjs(sub_stripe.cancel_at * 1000).format('DD MMM YYYY');
                            }

                            if(sub_stripe.canceled_at){
                                sub_stripe.canceled_at_beautiful = dayjs(sub_stripe.canceled_at * 1000).format('DD MMM YYYY');
                            }



                        }

                    }
                    this.listsub = JSON.parse(JSON.stringify(response.data));
                    for(let i = 0; i < this.listsub.length;  i++){
                        this.listsub[i].list_uta_ta = [];
                    }
                    this.$nextTick(() => {
                        this.listsub = response.data;
                    })

                });
            },
            utiliser(id_uta_ta){
                console.log("utiliser "+this.route_utal);

                axios.post(this.route_utal, {'id_uta_ta' : id_uta_ta, 'action' : -1},{

                }).then(response =>{
                    console.log(response)
                    this.refresh()
                });
            },
            waiting(){
                console.log('waiting');
                //document.getElementById('app_feed').dispatchEvent(new CustomEvent('refreshfeed', { detail: {}}))
            },
            row(){
                console.log('row');
            },
            listInvoiceload(){
                axios({
                    method: 'get',
                    url: this.route_invoices,
                }).then(response =>{

                    for (let i = 0; i <  response.data.invoices.length; i++) {
                        response.data.invoices[i]['period_start_readable']= dayjs(response.data.invoices[i]['period_start']).format('DD MMM YYYY');
                        response.data.invoices[i]['period_end_readable']= dayjs(response.data.invoices[i]['period_end']).format('DD MMM YYYY');
                        response.data.invoices[i]['created']= dayjs(response.data.invoices[i]['created']).format('DD MMM YYYY');
                    }

                    this.invoices = response.data.invoices;
                    if(this.invoices.length == 0){
                        this.displayAucuneFacture = true;
                    }else{

                    }
                });
            },
            cancelSubscriptionById(tgt,s_id, route_resiliation){

                tgt.disabled = true;

                console.log('cancel subscription' + s_id);

                axios.post(route_resiliation, {},{

                }).then(response =>{
                    console.log(response)
                    this.refresh()
                    tgt.disabled = false;
                    document.getElementById('modal-cancelsub').checked = false;
                });
            }
        }))
    })

}
