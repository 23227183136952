import $ from "jquery";
$(function() {
    $(document).on("submit", "#quickcreation_pro", function() {
        var e = this;
        // change login button text before ajax
        $(this).find("[type='submit']").prop( "disabled", true );
        console.log($(this).serialize())
        $.post($(this).attr('action'), $(this).serialize(), function(data) {

            window.location.reload()
        }).fail(function(response) {

            $(e).find("[type='submit']").prop( "disabled", false );


            console.log(response);
            console.log($(e));
        });
        return false;
    });

})
